<template>
  <div class="atb_con">
    <back />
    <div class="head_img">
      <img src="../../assets/images/loveman.png" alt />
    </div>
    <div class="bottom_div">
      <div class="wrapper">
        <div class="input_box">
          <div class="head_font">
            <span>*</span>
            <span>类型</span>
          </div>
          <div class="input_div" @click="class_window = true">
            <span
              :class="{ font_color: isSelectedOfType, textControl: true }"
              style="font-size:16px"
            >
              {{
              typeName
              }}
            </span>
            <img src="../../assets/images/xiajt.png" alt />
          </div>
        </div>
        <div class="input_box">
          <div class="head_font">
            <span>*</span>
            <span>名称</span>
          </div>
          <input v-model="submitForm.name" style="font-size:15px" type="text" placeholder="请输入名称" />
        </div>
        <div class="input_box">
          <div class="head_font">
            <span>*</span>
            <span>联系人</span>
          </div>
          <input
            v-model="submitForm.contactPeople"
            style="font-size:16px"
            type="text"
            placeholder="请输入联系人"
          />
        </div>
        <div class="input_box">
          <div class="head_font">
            <span>*</span>
            <span>手机号</span>
          </div>
          <input
            v-model="submitForm.phone"
            type="text"
            style="font-size:16px"
            placeholder="请输入手机号"
            @blur="onBlurOfphone"
          />
        </div>
        <div class="input_box">
          <div class="head_font">
            <span>*</span>
            <span>密码</span>
          </div>
          <input
            v-model="submitForm.password"
            type="password"
            style="font-size:16px"
            placeholder="请输入密码"
          />
        </div>
        <div class="input_box">
          <div class="head_font">
            <span>*</span>
            <span>所属区域</span>
          </div>
          <div class="input_div" @click="tree_show = true">
            <span
              :class="{ font_color: isSelectedOfTree, textControl: true }"
              style="font-size:16px"
            >
              {{
              regionName
              }}
            </span>
            <img src="../../assets/images/xiajt.png" alt />
          </div>
        </div>
        <div class="input_box">
          <div class="head_font">
            <span>*</span>
            <span>可提供服务</span>
          </div>
          <div class="input_div" @click="serve_show = true">
            <span
              :class="{ font_color: isSelectedOfServe, textControl: true }"
              style="font-size:16px"
            >
              {{
              serveName
              }}
            </span>
            <img src="../../assets/images/xiajt.png" alt />
          </div>
        </div>
        <div class="input_box">
          <div class="head_font">
            <span>*</span>
            <span>证件图片</span>
          </div>
          <van-uploader
            v-model="fileList"
            :before-read="beforeFileFUpload"
            :after-read="afterRead"
            :max-count="2"
            @delete="onDelete"
          />
        </div>
        <div class="submit_btn" :disabled="applyAble" @click="submitApply">提交申请</div>
      </div>
      <van-action-sheet
        v-model="serve_show"
        :actions="wishServeList"
        close-on-click-action
        cancel-text="取消"
        @cancel="onCancel"
        @select="onSelectOfServe"
      />
      <van-action-sheet
        v-model="class_window"
        :actions="wishClassList"
        close-on-click-action
        @select="onSelectOfClass"
      />
      <van-action-sheet class="tree_box" v-model="tree_show">
        <div>
          <tree :list="list" :treetype="1"></tree>
        </div>
      </van-action-sheet>
    </div>
  </div>
</template>

<script>
import wishApi from "@/api/wish";
import authApi from "@/api/auth";
import { Toast } from "vant";
import Tree from "../massorder/tree";
import Bus from "../../bus";
import peopleOrderApi from "@/api/peopleOrder";

export default {
  name: "ApplyToBeLove",
  data() {
    return {
      applyAble: false,
      submitForm: {
        id: null,
        type: "",
        name: "", // 名称
        contactPeople: "", // 联系人
        phone: "", // 手机号
        password: "", // 密码
        regionName: "", // 区域名称
        regionId: "", // 区域ID
        serverName: "", // 服务类型
        pic: [], // 证件图片
        appName: this.appName, // app名称
      },
      // 验证信息
      validateInfo: {
        type: "请选择类型",
        name: "请输入名称",
        contactPeople: "请输入联系人",
        phone: "请输入手机号码",
        password: "请输入密码",
        regionName: "请选择所属区域",
        regionId: "请选择所属区域",
        serverName: "请选择服务",
      },
      typeName: "请选择类型",
      regionName: "请选择所属区域",
      serveName: "请选择服务",
      fileList: [],
      params: {
        appName: this.appName,
      },
      bottom_window: false,
      actions: [{ name: "选项一" }, { name: "选项二" }, { name: "选项三" }],
      list: [],
      class_window: false, // 类型弹窗
      tree_show: false, // 树形列表弹窗
      serve_show: false, // 服务类型弹窗
      wishClassList: [
        { name: "个人", status: 0 },
        { name: "企业", status: 1 },
      ],
      wishServeList: [], // 服务类型
      isSelectedOfType: false,
      isSelectedOfTree: false,
      isSelectedOfServe: false,
      submitFlag: false,
    };
  },
  components: {
    Tree,
  },
  created() {
    Bus.$on("regionTree", (data) => {
      this.submitForm.regionName = data.name;
      this.regionName = data.name;
      this.submitForm.regionId = data.keyId;
      this.isSelectedOfTree = true;
      this.tree_show = false;
    });
    // console.log(this.appName);
  },
  mounted() {
    this.getWishClass();
    this.getRegionData();

  },
  methods: {
    // 获取服务类型
    getWishClass() {
      let params = {
        // app名字
        appName: this.appName,
      };
      wishApi.wishType(params).then((res) => {
        // console.log(res);
        if (res.success) {
          this.wishServeList = res.data;
        } else {
          Toast(`${res.message}`);
        }
      });
    },
    // 获取乡镇部门
    getRegionData() {
      let params = {
        appName: this.appName,
      };
      peopleOrderApi.regiontree(params).then((res) => {
        if (res.success) {
          this.list = res.data;
        } else {
          Toast(`${res.message}`);
        }
      });
    },
    // 类型切换
    onSelectOfClass(item) {
      // console.log(item);
      this.typeName = item.name;
      this.submitForm.type = item.status;
      this.isSelectedOfType = true;
    },
    // 服务切换
    onSelectOfServe(item) {
      // console.log(item.name);
      this.serveName = item.name;
      this.submitForm.serverName = item.name;
      this.isSelectedOfServe = true;
    },
    onCancel(file) {
      Toast("已取消");
    },
    // 点击删除按钮，删除pic中存储的图片链接
    onDelete(file) {
      // console.log(file);
      const index = file.activeIndex;
      // 删除pic数组中的指定索引的元素
      this.submitForm.pic.splice(index, 1);
      console.log(this.submitForm.pic);
    },
    // 图片上传之前
    beforeFileFUpload(file) {
      if (!this.submitForm.phone) {
        Toast("请先输入手机号在上传图片");
        return false;
      }
      const fileMaxSize = 20 * 1024 * 1024;

      var len = file.type.length;
      var arr = [];
      arr = file.type.split("/");
      const fileType = arr[0];
      // 获取后缀名
      let index = file.name.lastIndexOf(".");
      file.fileSuffix = file.name.substr(index + 1);
      if (file.size > fileMaxSize) {
        Toast("文件大小不能超过 20M");
        return false;
      }
      if (fileType !== "image") {
        Toast("请上传图片!");
        return false;
      }
      return true;
    },
    afterRead(file, detail) {
      file.activeIndex = detail.index;
      file.status = "uploading";
      file.message = "上传中...";
      this.submitFlag = false;
      var formData = new FormData();
      formData.append("file", file.file);
      var params = {
        mediaSuffix: file.file.suffix,
        deviceNumber: this.submitForm.phone,
      };
      wishApi
        .uploadSimpleMedia(formData, params)
        .then((res) => {
          // console.log(res);
          if (res.success) {
            file.status = "done";
            Toast("上传完成");
            this.submitFlag = true;
            this.submitForm.pic.push(res.data);
          } else {
            Toast(`${res.message}`);
          }
        })
        .catch((err) => {
          console.log(err);
          file.status = "failed";
          file.message = "上传失败";
          this.submitFlag = true;
        });
    },
    // 提交申请
    submitApply() {
      // console.log(this.submitForm);
      const flag = this.validateForm();
      if (!flag) {
        return false;
      }
      if (!this.submitFlag) {
        Toast("图片正在上传中！");
        return false;
      }
      this.submitForm.pic = JSON.stringify(this.submitForm.pic);
      this.applyAble = true;
      // console.log(this.submitForm);
      authApi
        .wishmemberAdd(this.submitForm)
        .then((res) => {
          this.applyAble = false;
          if (res.code == 10000) {
            Toast("申请成功,请等待管理员审核！");
            this.$router.go(-1);
          } else {
            Toast(`${res.message}`);
          }
        })
        .catch(() => {
          this.applyAble = false;
        });
    },
    validateForm() {
      for (let key in this.submitForm) {
        if (this.submitForm[key] === "") {
          Toast(this.validateInfo[key]);
          return false;
        }
      }
      if (this.fileList.length === 0) {
        Toast("请上传图片!");
        return false;
      }
      var phoneReg = /^[1][0-9]{10}$/;
      var passReg = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[a-zA-Z0-9]{8,16}$/;
      if (!phoneReg.test(this.submitForm.phone)) {
        Toast("请输入正确的手机号!");
        return false;
      } else if (this.submitForm.password.length < 6 || this.submitForm.password.length > 16) {
        Toast("密码必须在6-16位，且包含数字和字母!");
        return false;
      } else {
        return true;
      }
    },
    // 申请完成后，生成新的表单,并且清空之前的输入框的老数据
    createNewForm() {
      this.submitForm = {
        id: null,
        type: "",
        name: "",
        contactPeople: "",
        phone: "",
        password: "",
        regionName: "",
        regionId: "",
        serverName: "",
        pic: [],
        appName: this.appName,
      };
      this.typeName = "";
      this.regionName = "";
      this.serveName = "";
      this.fileList = [];
    },
    // 手机号输入框失焦触发，用于验证申请人的注册情况
    onBlurOfphone() {
      var params = {
        appName: this.appName,
        phone: this.submitForm.phone,
      };
      authApi.checkWishMemberIsTrueOther(params).then((resp) => {
        // console.log(resp);
        if (resp.data.id) {
          this.submitForm.id = resp.data.id;
        } else {
          this.submitForm.id = null;
        }
        if (resp.data.status === 0) {
          Toast("您的账号正在审核中，请勿重复申请!");
          this.submitForm.id = null;
        } else if (resp.data.status === 1) {
          Toast("您的账号已通过审核，请直接登录!");
          this.submitForm.id = null;
        } else if (resp.data.status === 2) {
          Toast("您的账号被驳回，请重新申请一次!");
          this.submitForm.id = null;
        }
      });
    },
  },
};
</script>
<style>
input::-webkit-input-placeholder {
  /* placeholder颜色  */

  /* placeholder字体大小  */
  font-size: 14px;
}
</style>
<style lang="less" scoped>
// .back {
//   font-size: 20px;
//   position: absolute;
//   left: 10px;
//   top: 30px;
//   z-index: 999;
//   color: #fff;
//   width: 40px;
//   height: 40px;
//   background: rgba(0, 0, 0, 0.5);
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border-radius: 100%;
// }
.atb_con {
  background: #f3f3f3;
  // height: 100%;
  width: 100%;
  // padding-bottom: 10vw;
  .head_img {
    img {
      width: 100%;
      height: 50vw;
    }
  }
  .bottom_div {
    padding: 5vw;
    .wrapper {
      background: #fff;
      padding: 5vw 5vw 0vw 5vw;
      margin-top: -30vw;
      position: relative;
      z-index: 2;
      border-radius: 7px;
      height: auto;
      overflow: hidden;
      .input_box {
        padding: 0 5vw;
        margin-top: 4vw;
        .head_font {
          margin-bottom: 2vw;
          span {
            &:nth-child(1) {
              color: red;
              margin-right: 5px;
            }
            &:nth-child(2) {
              font-size: 0.34rem;
            }
          }
        }
        .input_div {
          position: relative;
          color: #959595;
          border: #eaeaea solid 1px;
          height: 9vw;
          line-height: 9vw;
          padding-left: 2vw;
          font-size: 14px;
          .font_color {
            color: #000;
          }
          .textControl {
            display: inline-block;
            width: 63vw;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          img {
            position: absolute;
            width: 6vw;
            right: 0;
            top: 0;
          }
        }
        input::-webkit-input-placeholder {
          /* 修改字体颜色 */
          color: #959595;
          font-size: 15px;
          padding-left: 2vw;
        }
        input {
          width: 99%;
          border: #eaeaea 1px solid;
          height: 9vw;
          line-height: 9vw;
          -webkit-appearance: none;
          outline: none;
        }
      }
      .submit_btn {
        width: 60vw;
        background: #1377e2;
        height: 10vw;
        line-height: 10vw;
        text-align: center;
        color: #fff;
        font-size: 0.37rem;
        border-radius: 5px;
        margin: 20px auto;
      }
    }
  }
  .tree_box {
    padding: 5vw 0 10vw;
    background: #f3f3f3;
  }
}
</style>
